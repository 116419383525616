.container_todo_list_ul{
    margin:0 auto;
    min-width:300px;
    max-width:600px;
    padding: 20px;
    background: var(--medium-color);
    min-height: 400px;
    border-radius: 10px;
    position: relative;
    bottom: 70px;
    box-shadow: 6px 6px 5px 2px rgba(7,34,39,0.35);
}