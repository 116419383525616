.container_search{
 margin-top: 50px;
 display: flex;   
}
.container_search .search{
    max-width:200px;
    margin: 0 auto;
    background-color: white;
    font-size: 15px;
    text-align: center;
}