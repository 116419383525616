.containerSpiner{
    margin-top: 100px;
    margin-bottom: 100px;
   
}


.containerSpiner .LoadingSpiner{
    margin: 0 auto;
    border: 4px solid rgba(0, 0, 0, .1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-left-color: rgba(103, 137, 138, 1);
    animation: spin 1s linear infinite;
}

@keyframes spin{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}