
body{
  margin: 0;
  padding: 0;
  
}
.container_head{
  background-image: url(https://images.pexels.com/photos/210661/pexels-photo-210661.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height:400px;
}

.title{
  padding-top: 25px;
  text-align: center;
  color:var( --ligth-color);
  font-weight: bold;
  font-size:3rem;
}

