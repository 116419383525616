.containerEmptyTodo{
    padding-top: 50px;
    padding-bottom: 50px;
    background: #00B4DB;  
    background: -webkit-linear-gradient(to right, #0083B0, #00B4DB);  
    background: linear-gradient(to right, #0083B0, #00B4DB); 
    margin-bottom: 50px;

}

.containerEmptyTodo p, .containerEmptyTodo h3{
    text-align: center;
    font-size: 20px;
    color: azure;
}